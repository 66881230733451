import { ResolveFn } from '@angular/router';
import { BackendService } from "./services/backend.service";
import { inject } from "@angular/core";
import { map, tap } from "rxjs";
import { SearchOption } from "./types/types";

export const risksResolver: ResolveFn<SearchOption[]> = (route, state) => {
  const backendService = inject(BackendService);
  return backendService.get$<SearchOption[]>("/risks").pipe(
    map((response) => response.body!),
    tap((risks) => {
      localStorage.setItem('Risks', JSON.stringify(risks));
    })
  );
};
