import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UserService } from './user.service';
import { catchError, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RequestInterceptService implements HttpInterceptor {
    private userService = inject(UserService);

    public intercept(request: HttpRequest<any>, next: HttpHandler) {
        const accessToken = this.userService.getAccessToken();
        if (accessToken) {
            const headers = request.headers.set('Authorization', accessToken);
            request = request.clone({ headers });
        }
        return next.handle(request); /* .pipe(
            catchError((err: HttpErrorResponse) => {
                console.warn(err.error + " " + err.message)
                return throwError(() => err)
            })
        ) */
    }
}
