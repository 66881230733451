import { Component, OnInit, inject } from '@angular/core';
import { UserService } from './services/user.service';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { PortfolioListService } from './services/portfolio-list.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
    private userService = inject(UserService);
    private portfolioList = inject(PortfolioListService);

    title = 'client';

    ngOnInit(): void {
        this.userService.userLoaded.subscribe(() => {
            this.portfolioList.loadList();
        });
        this.userService.initUser();
    }
}
