import { Portal, PortalModule } from '@angular/cdk/portal';

import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PortalService } from 'src/app/services/portal.service';
import { UserService } from 'src/app/services/user.service';

interface MenuListItem {
    name: string;
    click: () => void;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [MatMenuModule, MatToolbarModule, MatButtonModule, MatIconModule, PortalModule, RouterModule],
})
export class HeaderComponent {
    private userService = inject(UserService);
    private router = inject(Router);
    private portalService = inject(PortalService);
    private authService = inject(AuthService);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public portal: Portal<any> | undefined;

    public menuList: MenuListItem[] = [
        { name: 'Login', click: () => this.router.navigate(['login']) },
        {
            name: 'Register',
            click: () => this.router.navigate(['register']),
        },
    ];

    constructor() {
        this.userService.userLoaded.subscribe(() => {
            this.menuList = [
                {
                    name: 'Portfolio list',
                    click: () => this.router.navigate(['list']),
                },
                {
                    name: 'Browse',
                    click: () => this.router.navigate(['browse']),
                },
                {
                    name: 'Upload',
                    click: () => this.router.navigate(['upload']),
                },
                { name: 'User', click: () => this.router.navigate(['user']) },
                { name: 'Login', click: () => this.router.navigate(['login']) },
                { name: 'Logout', click: () => this.authService.logout() },
                {
                    name: 'Register',
                    click: () => this.router.navigate(['register']),
                },
            ];
        });
        this.portalService.headerPortal.subscribe((portal) => {
            this.portal = portal;
        });
    }

    public get uri(): string {
        return this.userService.getCurrent().uri;
    }

    public goTo(): void {
        this.router.navigate(['user', this.uri]);
    }

    public get isLoggedIn(): boolean {
        return this.userService.isLoggedIn();
    }
}
