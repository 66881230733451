/// <reference types="@angular/localize" />
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { ChangeDetectionStrategy, importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { RequestInterceptService } from './app/services/request-intercept.service';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { RouteReuseService } from './app/services/route-reuse.service';
import { LANGUAGES, languages } from './app/searchOptions.constants';

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptService,
            multi: true,
        },
        // { provide: RouteReuseStrategy, useClass: RouteReuseService },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        // {
        //     provide: ChangeDetectionStrategy,
        //     useValue: ChangeDetectionStrategy.OnPush
        // },
        { provide: LANGUAGES, useValue: languages },
    ],
}).catch((err) => console.error(err));
