<div class="flex h-full px-5">
    <div class="flex items-center basis-1/4 align-center">
        <a class="text-decoration-none" [routerLink]="['/']">
            <h1 class="m-0">ESG Frontend</h1>
        </a>
    </div>
    <div class="flex items-center justify-end basis-3/4 gap-4">
        <ng-template [cdkPortalOutlet]="portal"></ng-template>
        <button [matMenuTriggerFor]="menu" class="ms-4">
            <i class="er-icon er-icon-menu"></i>
        </button>
        <mat-menu #menu="matMenu" [class]="'main-menu'">
            <div class="flex flex-col items-stretch">
                @for (item of menuList; track item.name) {
                    <a type="button" mat-button (click)="item.click()">{{ item.name }}</a>
                }
            </div>
        </mat-menu>
    </div>
</div>
