import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ListComponent } from './pages/list/list.component';
import { PortfolioEditComponent } from './pages/portfolio-edit/portfolio-edit.component';

import { PortfolioComponent } from './pages/portfolio/portfolio.component';

import { authGuard } from './auth/auth.guard';
import { BrowseComponent } from './pages/browse/browse.component';

import { taxonomyListResolver } from './taxonomy-list.resolver';
import { risksResolver } from "./risks.resolver";

const routes: Routes = [
    {
        path: '',
        redirectTo: '/browse',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadComponent: () => import('./pages/login/login.component').then((m) => m.LoginComponent),
    },
    {
        path: 'register',
        loadComponent: () => import('./pages/register/register.component').then((m) => m.RegisterComponent),
    },
    {
        path: 'reset',
        loadComponent: () =>
            import('./pages/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
    },
    {
        path: 'activate',
        loadComponent: () =>
            import('./pages/activation/activation.component').then((m) => m.ActivationComponent),
    },
    {
        path: 'update-password',
        loadComponent: () =>
            import('./pages/activation/activation.component').then((m) => m.ActivationComponent),
    },
    {
        path: 'list',
        loadComponent: () =>
            import('./pages/portfolios/portfolios.component').then((m) => m.PortfoliosComponent),
        canActivate: [authGuard],
    },
    {
        path: 'browse',
        loadComponent: () => import('./pages/company/company.component').then((m) => m.CompanyComponent),
        canActivate: [authGuard],
        resolve: {taxonomyList: taxonomyListResolver, risks: risksResolver},
    },
    {
        path: 'portfolio/:id',
        loadComponent: () => import('./pages/company/company.component').then((m) => m.CompanyComponent),
        canActivate: [authGuard],
        resolve: {taxonomyList: taxonomyListResolver, risks: risksResolver},
    },
    {
        path: 'user',
        loadComponent: () => import('./pages/user/user.component').then((m) => m.UserComponent),
        canActivate: [authGuard],
    },
    {
        path: 'upload',
        loadComponent: () => import('./pages/upload/upload.component').then((m) => m.UploadComponent),
        canActivate: [authGuard],
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
