import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
    const loggedIn = inject(UserService).isLoggedIn();
    if (!loggedIn) {
        return inject(Router).parseUrl('/login');
    }
    return true;
};
