import { ResolveFn } from '@angular/router';
import { DataService } from './services/data.service';
import { inject } from '@angular/core';
import { forkJoin, map } from 'rxjs';
import { Standard, TaxonomyListItem } from './types/types';

export const taxonomyListResolver: ResolveFn<TaxonomyListItem[]> = () => {
    const dataService = inject(DataService);
    const keys = Object.keys(Standard).filter((key) => isNaN(Number(key))) as (keyof typeof Standard)[];
    return forkJoin(
        keys.map((key) => {
            return dataService.getTaxonomyList(Standard[key]).pipe(
                map((items) => {
                    return {
                        [key]: items,
                    } as TaxonomyListItem;
                }),
            );
        }),
    );
};
