import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PortalService {
    headerPortal = new Subject<Portal<any> | undefined>();
    constructor() {}
}
